import React, { useEffect } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { BsCheck } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Footer from "@components/Footer/Footer";
import RequirementToJoin from "@components/RequirementToJoin";
import HeaderNav from "@components/HeaderNavV2/HeaderNav";
import CardGallery from "@components/CardGallery/CardGallery";
import Card from "@components/Card/Card";
import Title from "@atoms/Title/Title";
import Text from "@atoms/Text/Text";
import Layout from "@components/layout";
import Seo from "@components/seo";
import Button from "@atoms/Button/Button";
import Section from "@atoms/Section/Section";
import HeaderMinimal from "@components/HeaderMinimal/HeaderMinimal";
import SideSection from "@components/SideSection/SideSection";
import { CmsMappedComponent } from "@components/CMSComponents";
import Table from "@components/Table/Table";
import TableItem from "@components/Table/TableItem";
import "./TheAepsyWay.scss";
import HeroIcon from "@atoms/HeroIcon/HeroIcon";
import LanguageNotSupported from "@components/LanguageNotSupported";
import { getTitleGroup } from "@components/CMSComponents/helpers";
import TheAepsyWayAnalytics from "./TheAepsyWayAnalytics";
import {
	usePageConfiguration,
	PageConfigurationName,
} from "@hooks/usePageConfiguration";
import { correctUrlByPageConfiguration } from "@helpers/GlobalHelpers";
import { withLocale, withTracking } from "@hocs";
import { useTracking } from '@hooks';

export const query = graphql`
    query($language: String!, $locale: AEPSYCMS_I18NLocaleCode) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        headerMobileImage: file(
            relativePath: { eq: "the-aepsy-way/header-aepsy-way-mobile.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        headerDesktopImage: file(
            relativePath: { eq: "the-aepsy-way/header-aepsy-way-desktop.jpg" }
        ) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 1440) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        communityPicture: file(
            relativePath: { eq: "the-aepsy-way/visual-many-providers.png" }
        ) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        illustrationForCoach: file(
            relativePath: { eq: "the-aepsy-way/illustration-for-coach.png" }
        ) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 150) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        illustrationForTherapist: file(
            relativePath: { eq: "the-aepsy-way/illustration-for-therapist.png" }
        ) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 150) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        aepsyCms {
            theAepsyWay(locale: $locale) {
                benefits {
                    ... on AEPSYCMS_ComponentCommonOrderedCard {
                        id
                        highlightedText
                        subtitle
                        cardTitle: title
                        index
                        __typename
                    }
                }
                comparisonItems {
                    ... on AEPSYCMS_ComponentAtomsTitleGroup {
                        id
                        subtitle
                        title
                        __typename
                    }
                    ... on AEPSYCMS_ComponentCommonComparisonItem {
                        id
                        option1
                        option2
                        text
                        __typename
                    }
                }
                otherEntries {
                    ... on AEPSYCMS_ComponentCommonOrderedCard {
                        id
                        images {
                            media
                            src {
                                url
                                urlSharp {
                                    childImageSharp {
                                        fluid(maxWidth: 250, toFormat: WEBP, fit: CONTAIN) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                        subtitle
                        cardTitle: title
                        buttons {
                            label
                            to
                            variant
                        }
                        __typename
                    }
                    ... on AEPSYCMS_ComponentCommonZsrNumberCallout {
                        id
                        calloutContent: content
                        index
                        subtitle
                        title
                        __typename
                    }
                    ... on AEPSYCMS_ComponentAtomsTitleGroup {
                        id
                        subtitle
                        title
                        __typename
                    }
                }
                analytics {
                    ... on AEPSYCMS_ComponentCommonOrderedCard {
                        id
                        highlightedText
                        subtitle
                        cardTitle: title
                        index
                        __typename
                    }
                    ... on AEPSYCMS_ComponentAtomsTitleGroup {
                        id
                        subtitle
                        title
                        __typename
                    }
                }
            }
        }
    }
`;

const TheAepsyWayPage = ({ data }) => {
	const { t } = useTranslation();
	const { trackPageView } = useTracking();
	useEffect(() => {
		trackPageView();
	}, []);
	const pageConfiguration = usePageConfiguration({
		name: PageConfigurationName.theAepsyWay,
	});

	useEffect(() => {
		if (pageConfiguration) {
			correctUrlByPageConfiguration(pageConfiguration);
		}
	}, [pageConfiguration]);

	// Header mobile
	const static_images_sources = [
		data.headerMobileImage.childImageSharp.fluid,
		{
			...data.headerDesktopImage.childImageSharp.fluid,
			media: `(min-width: 750px)`,
		},
	];

	const renderEntryByType = (data: any) => {
		switch (data.__typename) {
			case "AEPSYCMS_ComponentAtomsTitleGroup":
				return <CmsMappedComponent data={data} />;
			case "AEPSYCMS_ComponentCommonOrderedCard":
				return <CmsMappedComponent data={data} />;
			case "AEPSYCMS_ComponentCommonZsrNumberCallout":
				return <CmsMappedComponent data={data} />;
			default:
				return <></>;
		}
	};

	const renderOptionIcon = (value: boolean) => {
		if (Boolean(value))
			return (
				<HeroIcon theme="success" size="xs">
					<BsCheck />
				</HeroIcon>
			);
		return (
			<HeroIcon theme="error" size="xs">
				<IoMdClose />
			</HeroIcon>
		);
	};

	const renderContent = () => {
		const {
			aepsyCms: { theAepsyWay: theAepsyWayData },
		} = data;

		if (!theAepsyWayData) return <LanguageNotSupported />;
		const { comparisonItems, benefits, otherEntries } = theAepsyWayData;

		const sortedData = otherEntries.sort((a: any, b: any) => a.index - b.index);

		return (
			<>
				<Section container="large" spacingBottom="m">
					<div className="TheAepsyWay--BenefitsBlock">
						<CardGallery size="3" layout="cascade" variant="mobileInline">
							{benefits.map((item: any) => (
								<Card
									format="v"
									variant="heroTitle"
									visual={
										<Title size="xxl" font="alt" theme="light">
											{item.highlightedText}
										</Title>
									}
									title={item.cardTitle}
									text={item.subtitle}
								/>
							))}
						</CardGallery>
					</div>
				</Section>

				{/* COMPARISION */}
				<Section container="short">
					<CmsMappedComponent data={getTitleGroup(comparisonItems)} />
				</Section>

				<Section spacing="m" container="large">
					<Table variant="flex">
						<TableItem>
							<strong className="flex-hero flex-none">Aepsy</strong>
							<strong className="flex-subs">Aepsy</strong>
							<strong className="flex-subs-2">
								{t("the.aepsy.way.work.with.health.insurance")}
							</strong>
						</TableItem>
						{comparisonItems
							.filter(
								(item: any) =>
									item.__typename === "AEPSYCMS_ComponentCommonComparisonItem",
							)
							.map((item: any) => (
								<TableItem key={item.id}>
									<strong className="flex-hero">{item.text}</strong>
									<span className="flex-subs">
										{renderOptionIcon(item.option1)}
									</span>
									<span className="flex-subs-2">
										{renderOptionIcon(item.option2)}
									</span>
								</TableItem>
							))}
					</Table>
				</Section>

				{/* QUOTE */}
				<Section container="short" spacing="xl" theme="dark">
					<div className="g_center">
						<Title align="center" size="xl" theme="white">
							"{t("the.aepsy.way.quote.title")}"
						</Title>
						<Text size="l" theme="white" align="center">
							{t("the.aepsy.way.quote.subtitle")}
						</Text>
					</div>
				</Section>

				<Section spacing="m">
					<TheAepsyWayAnalytics />
				</Section>

				{/* Part of a community */}
				<Section container="large" theme="white" spacing="s">
					<SideSection
						title={t("the.aepsy.way.community.title")}
						text={t("the.aepsy.way.community.text")}
						link={
							<AnchorLink offet={() => 40} href="#provider-signup">
								<Button
									variant="inline"
									theme="highlighted"
									label={t("button.join.aepsy.now")}
									iconArrow
								/>
							</AnchorLink>
						}
						visual={
							<Img
								fluid={data.communityPicture.childImageSharp.fluid}
								alt="Part of a community Image"
							/>
						}
					/>
				</Section>

				{/* OTHER ENTRIES */}
				<Section container="short" spacingTop="l">
					{sortedData.map((item: any) => (
						<Section spacingBottom="s" key={item.id}>
							{renderEntryByType(item)}
						</Section>
					))}
				</Section>
			</>
		);
	};

	return (
		<Layout>
			<Seo
				title={t("the.aepsy.way.seo.title")}
				description={t("the.aepsy.way.seo.description")}
			/>
			<div className="global_theme-green TheAepsyWay">
				<HeaderNav theme="white" signUpAsProviderShown />
				<Section theme="light">
					<HeaderMinimal
						theme="light"
						title={t("the.aepsy.way.header.title")}
						subtitle={t("the.aepsy.way.header.subtitle")}
						btnPrimary={
							<AnchorLink offset={() => 70} href="#mehr">
								<Button
									variant="primary"
									theme="white"
									label={t("the.aepsy.way.header.cta")}
									size="l"
								/>
							</AnchorLink>
						}
						imageSrc={static_images_sources}
					/>
				</Section>

				<div id="mehr">{renderContent()}</div>

				<Section spacingBottom="l" container="large">
					<RequirementToJoin
						customTitle="the.aepsy.way.requirements.to.join.title"
						imageForTherapist={
							data.illustrationForTherapist.childImageSharp.fluid
						}
						imageForCoach={data.illustrationForCoach.childImageSharp.fluid}
					/>
				</Section>
				<Footer />
			</div>
		</Layout>
	);
};

export default withTracking(withLocale(TheAepsyWayPage));
